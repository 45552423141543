import React from "react";
import { FaSync } from "react-icons/fa";
import "./floatingBadge.scss";

const FloatingBadge = () => {
  return (
    <div className="floating-update-badge">
      <div className="badge-content">
        <FaSync className="sync-icon" />
        <span className="badge-text gradient-text">הנתונים נאספים בכל יום</span>
      </div>
      <div className="badge-glow"></div>
    </div>
  );
};

export default FloatingBadge;
