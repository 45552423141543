import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "./bannerOne.scss";
import FloatingBadge from "../../components/common/FloatingBadge";
const BannerOne = () => {
  return (
    <div className="banner banner-style-1">
      <FloatingBadge />
      <div className="container">
        <div className="row align-items-end align-items-xl-start">
          <div className="col-lg-6">
            <div className="banner-content">
              <div className="promo-container">
                <AnimationOnScroll
                  animateIn="fadeInUp"
                  delay={100}
                  animateOnce={true}
                >
                  <h1 className="title gradient-text">
                    הגדילו את ההכנסות שלכם עם דירוג ראשון בגוגל
                  </h1>
                </AnimationOnScroll>
                <AnimationOnScroll
                  animateIn="fadeInUp"
                  delay={200}
                  animateOnce={true}
                >
                  <span className="subtitle highlight-text">
                    גלו איך העסקים המובילים מרוויחים יותר כסף דרך דירוג גבוה
                    בגוגל. עקבו אחר מילות המפתח החשובות, נתחו את המתחרים, וקבלו
                    המלצות מעשיות להגדלת התנועה והמכירות באתר שלכם
                  </span>
                </AnimationOnScroll>

                <AnimationOnScroll
                  animateIn="fadeInUp"
                  delay={300}
                  animateOnce={true}
                >
                  <div className="cta-container">
                    <a
                      href={"https://dashboard.weseo.app/"}
                      className="axil-btn btn-fill-primary btn-large pulse-animation"
                      target="_blank"
                      aria-label="נפתח בטאב חדש"
                    >
                      התחילו להרוויח יותר
                    </a>
                    <div className="promo-tag shine-effect">
                      <span className="promo-text">
                        14 ימי ניסיון חינם ללא פרטי אשראי{" "}
                      </span>
                      <span className="promo-icon bounce">💰</span>
                    </div>
                  </div>
                </AnimationOnScroll>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="banner-thumbnail">
              <div className="large-thumb">
                <img
                  src={
                    //process.env.PUBLIC_URL + "/images/banner/laptop-poses.png"
                    process.env.PUBLIC_URL + "/images/device.png"
                  }
                  alt="Laptop"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul className="list-unstyled shape-group-21">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-39.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-38.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-4">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-5">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-6">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-40.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-7">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-41.png"}
            alt="Bubble"
          />
        </li>
      </ul>
    </div>
  );
};

export default BannerOne;
