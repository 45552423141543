import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.scss";

import compe from "./assets/compe.png";
import stats from "./assets/stats.png";
import words from "./assets/words.png";
import reports from "./assets/reports.png";
import you from "./assets/you.png";
import icon1 from "./assets/icon-1.png";
import icon2 from "./assets/icon-2.png";
import icon3 from "./assets/icon-3.png";
import icon4 from "./assets/icon-4.png";
import icon5 from "./assets/icon-5.png";
import research from "./assets/research.png";

const titles = [
  {
    id: 0,
    value: "מעקב מתחרים",
    data: "מעקב מתחרים מאפשר להוסיף אתרים רלוונטיים ולנתח את מיקומיהם בגוגל ביחס למילות המפתח שאתם מקדמים, מה שמספק תמונת מצב ברורה על הביצועים שלכם לעומת המתחרים ומאפשר זיהוי הזדמנויות לשיפור.",
    picture: compe,
    icon: icon5,
  },
  {
    id: 1,
    value: "מעקב מילות חיפוש",
    data: "המערכת אוספת נתונים בזמן אמת על מילות המפתח שלכם, כולל דירוג נוכחי, שינויים ביחס לעדכון הקודם, ומגמות שיפור או ירידה. כך תוכלו לעקוב בקלות אחר ההתקדמות שלכם ולזהות הזדמנויות לשיפור.",
    picture: words,
    icon: icon4,
  },
  {
    id: 2,
    value: "דוחות",
    data: "המערכת מפיקה דוחות מותאמים אישית שמציגים את מיקומי מילות המפתח, מצב טכני של האתר כולל דומיין ו-SSL, והמלצות ברורות לשיפור הביצועים והחשיפה בגוגל",
    picture: reports,
    icon: icon1,
  },
  {
    id: 3,
    value: "סטטיסטיקות",
    data: "ניתן לצפות בנתונים סטטיסטיים מרכזיים, כמו ממוצע מיקומי מילות המפתח שלכם ותצוגה גרפית של מגמות לאורך זמן. כל זה עוזר לכם להבין את המצב הנוכחי שלכם ואת ההתקדמות הכללית בקידום האתר.",
    picture: stats,
    icon: icon3,
  },
  {
    id: 4,
    value: "אתה מול המתחרים",
    data: "אחת התכונות הבולטות היא האפשרות להשוות את הדירוגים שלכם למתחרים על מילות מפתח מסוימות. תוכלו לראות בגרפים ברור את מיקומכם ביחס למתחרים לאורך זמן, ולהשתמש בנתונים כדי לתכנן את אסטרטגיית הקידום הבאה שלכם.",
    picture: you,
    icon: icon2,
  },
  {
    id: 5,
    value: "מחקר מילות מפתח",
    data: "עם הכלי המתקדם שלנו, תוכל לגלות אילו מילות מפתח באמת שוות קידום, לראות בדיוק כמה תנועה יש להן בגוגל, ולדעת מה רמת התחרות עליהן – כך שתוכל להשקיע חכם ולדרג את האתר שלך למעלה במהירות. במקום לנחש, הכלי מנתח עבורך את השוק ומכוון אותך ישירות למילים עם הפוטנציאל הגבוה ביותר להצלחה. 🔥",
    picture: research,
    icon: icon2,
  },
];

const TestimonialItem = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    rtl: true,
  };

  return (
    <div className="testimonial-slider-wrapper">
      <div className="testimonial-slider">
        <Slider {...settings}>
          {titles.map((title) => (
            <div key={title.id} className="testimonial-slide">
              <div className="slide-content">
                <div className="slide-header">
                  <img
                    src={title.icon}
                    alt={title.value}
                    className="slide-icon"
                  />
                  <h2 className="slide-title">{title.value}</h2>
                </div>
                <p className="slide-description">{title.data}</p>
                <div className="slide-image-container">
                  <img
                    src={title.picture}
                    alt={title.value}
                    className="slide-image"
                  />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TestimonialItem;
