import React from "react";

const CtaLayoutOne = () => {
  return (
    <div className="section call-to-action-area">
      <div className="container">
        <div className="call-to-action">
          <div className="section-heading heading-light">
            <span className="subtitle">בואו נעבוד יחד</span>
            <h2 className="title">
              בעזרת המערכת שלנו תוכלו להגיע למקומות הגבוהים בגוגל ולהגדיל את
              הרווחים שלכם!
            </h2>
            <a
              href={"https://dashboard.weseo.app/"}
              className="axil-btn btn-large btn-fill-white"
              target="_blank"
              aria-label="נפתח בטאב חדש"
            >
              מעבר למערכת
            </a>
          </div>
          <div className="thumbnail">
            <div
              className="larg-thumb"
              data-sal="zoom-in"
              data-sal-duration="600"
              data-sal-delay="100"
            >
              <img
                className="paralax-image"
                src={process.env.PUBLIC_URL + "/images/others/chat-group.png"}
                alt="Chat"
              />
            </div>
          </div>
        </div>
      </div>
      <ul className="list-unstyled shape-group-9">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-12.png"}
            alt="Comments"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"}
            alt="Comments"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-13.png"}
            alt="Comments"
          />
        </li>
        <li className="shape shape-4">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-14.png"}
            alt="Comments"
          />
        </li>
        <li className="shape shape-5">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"}
            alt="Comments"
          />
        </li>
        <li className="shape shape-6">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-15.png"}
            alt="Comments"
          />
        </li>
        <li className="shape shape-7">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-16.png"}
            alt="Comments"
          />
        </li>
      </ul>
    </div>
  );
};

export default CtaLayoutOne;
